import { FC, useEffect } from 'react';

import * as braze from '@braze/web-sdk';

import { useFeatureFlag } from 'shared/src/feature-flags/use-feature-flag';

import { useAuth } from 'mats/src/components/auth/use-auth';

export const BrazeSdk: FC = () => {
  const user = useAuth();
  const { value: manualBrazeSdkEnabled } = useFeatureFlag('manualBrazeSdkEnabled', false);

  useEffect(() => {
    if (manualBrazeSdkEnabled) {
      braze.initialize(import.meta.env.VITE_BRAZE_API_KEY, {
        baseUrl: import.meta.env.VITE_BRAZE_URL,
        allowUserSuppliedJavascript: true,
      });
      braze.automaticallyShowInAppMessages();

      const userId = user.getAuthenticatedUserId();
      if (userId) {
        braze.changeUser(userId.toString());
      }

      braze.openSession();
    }
  }, [user, manualBrazeSdkEnabled]);

  return null;
};
